import { FiberManualRecord, FilterAlt, CalendarToday, Speed, BarChart } from "@mui/icons-material";
import styles from "./index.module.css"
import { CloudDownloadRounded, DateRange, Print } from "@material-ui/icons";
import CustomPieChart from "../../components/CustomPieChart"
import { DateCalendar, LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import Chart from "react-google-charts";
import { Badge, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Filter from "./filter";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { gestorService } from "../../services/gestor_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { coletaService } from "../../services/coleta_service";
import moment from "moment";
import { makeConsumptionByDay,  makeFlowWater, makeHoursWorking, makePumpedDays } from "../../util/utils";
import ObjectTable from "../../default/table/ObjectTable";
import { headRowsAguaDetalhado } from "../../constants/headRows";
import { colors } from "../../constants/dc_constants";
import { closeProgressDialog, makeProgressDialog } from "../../default/dialogs/DialogRefInterface";
import { htmlToPng, template_completo } from "../../default/templates_pdf/template_relatorio";
import { ptBR } from "date-fns/locale";

const volumeDiarioOptions = {
  colors: ['#74c464'],
  legend: { position: "none" },
  seriesType: "bars",
  series: {
    1: {
      type: "area",
      // type: "bar",
      color: '#d7ffcf',
    },
    2: {
      type: "line",
      color: "#798a81",
      lineDashStyle: [8, 8],
    },
    3: {
      type: "line",
      color: "#ff2020",
      lineDashStyle: [4, 4],
    }
  },
  chartArea: {
    left: "1rem",
    width: "90%",
    height: "85%"
  }
}

const vazaoOptions = {
  colors: ['#4285F4'],
  legend: { position: "none" },
  seriesType: "area",
  areaOpacity: 1,
  vAxis: {
    minValue: 0,
  },
  hAxis: {
    slantedText: true,
    slantedTextAngle: 13,
    textStyle: {
      fontSize: 10,
      color: colors.PRIMARY,
    },
  },
  series: {
    1: {
      type: "line",
      color: "#FF2020",
      lineDashStyle: [8, 8],
    }
  },
  chartArea: {
    left: "1rem",
    width: '90%',
    height: '85%'
  }
}

const dataGroup = [
  {
    value: 1,
    label: "mensal"
  },
  {
    value: 2,
    label: "semanal"
  },
  {
    value: 3,
    label: "diario"
  },
  {
    value: 4,
    label: "desagrupado"
  }
]

function serverDay(props) {
  const { pumpedDays = [], overPumpedDays = [], day, outsideCurrentMonth } = props;

  const newDay = day.toLocaleDateString('pt-BR');
  const isPumpedDay = !outsideCurrentMonth && pumpedDays.indexOf(newDay) >= 0;
  const isOverPumpedDay = !outsideCurrentMonth && !isPumpedDay && overPumpedDays.indexOf(newDay) >= 0;
  const noPumpedDay = !outsideCurrentMonth && !isPumpedDay && !isOverPumpedDay;

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={undefined}
    >
      <PickersDay
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          ...(isPumpedDay && {
            backgroundColor: '#A1DD70',
            '&:hover': {
              backgroundColor: 'green',
            },
          }),
          ...(isOverPumpedDay && {
            backgroundColor: '#f2706f',
            '&:hover': {
              backgroundColor: 'red',
            },
          }),
          ...(noPumpedDay && {
            backgroundColor: '#D9D9D9',
            '&:hover': {
              backgroundColor: '#ababab',
            },
          })
        }}
      />
    </Badge>
  );
}

function RelatoriosWater(selected) {

  const [gestores, setGestores] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [gestorSelected, setGestorSelected] = useState(selected);
  const [gestorData, setGestorData] = useState([]);
  const [outorga, setOutorga] = useState({});
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const [currentVolume, setCurrentVolume] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dailyVolume, setDailyVolume] = useState([]);
  const [pumpedDays, setPumpedDays] = useState([]);
  const [overPumpedDays, setOverPumpedDays] = useState([]);
  const [hoursWorking, setHoursWorking] = useState(0);
  const [flowData, setFlowData] = useState([]);
  const [grouping, setGrouping] = useState(4);
  const [report, setReport] = useState();
  const [volumePercentage, setVolumePercentage] = useState(0);
  const [headRowsDetailed, setHeadRowDetailed] = useState(headRowsAguaDetalhado);

  const filterRef = useRef(null)

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  let dadosTableRef = React.createRef();

  const toolActions = [
    {
      title: "Baixar XLS",
      label: "Baixar",
      color: colors.BLUE,
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        dadosTableRef.current.getExportRowsXls(null, true, false);
      },
    },
  ];

  function getColetas() {
    coletaService.getColetasAgua({
      gestor_id: gestorSelected.id,
      data_inicial: currentMonth,
      data_final: currentMonth.clone().endOf('month')
    }, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        setGestorData(response.data)
      }
    })
  }

  const handleFilter = (data) => {
    if (data.gestorSelected != gestorSelected) {
      setGestorSelected(data.gestorSelected);
    }
    if (data.periodSelected.dateLabel != currentMonth.format('MM/YYYY')) {
      setCurrentMonth(moment(data.periodSelected.date));
    }
    setShowFilters(false)
  };

  function handleChangeGroup(groupedSelected) {
    setGrouping(groupedSelected);
    let reportData;
    switch (groupedSelected) {
      case 1:
        reportData = [...gestorData.groupedByMonth].map(item => ({
          data_coleta: moment(item.endDate).endOf('day'),
          consumo: item.consumptionSum,
          vazao: item.flowSum,
          volumeOutorgado: outorga.volume,
          vazaoOutorgada: outorga.vazao
        }))
        setHeadRowDetailed(headRowsAguaDetalhado.slice(1));
        setReport(reportData);
        break
      case 2:
        reportData = [...gestorData.groupedByWeek].map(item => ({
          data_coleta: moment(item.endDate).endOf('day'),
          consumo: item.consumptionSum,
          vazao: item.flowSum,
          volumeOutorgado: outorga.volume,
          vazaoOutorgada: outorga.vazao
        }));
        setHeadRowDetailed(headRowsAguaDetalhado.slice(1));
        setReport(reportData);
        break
      case 3:
        reportData = [...gestorData.groupedByDay].map(item => ({
          data_coleta: moment(item.date).endOf('day'),
          consumo: item.consumptionSum,
          vazao: item.flowSum,
          volumeOutorgado: outorga.volume,
          vazaoOutorgada: outorga.vazao,
        }));
        setHeadRowDetailed(headRowsAguaDetalhado.slice(1));
        setReport(reportData);
        break
      case 4:
        reportData = [...gestorData.ungrouped].map(item => ({
          ...item,
          volumeOutorgado: outorga.volume,
          vazaoOutorgada: outorga.vazao
        }))
        setHeadRowDetailed(headRowsAguaDetalhado);
        setReport(reportData);
        break
    }
  };

  useEffect(() => {
    try {
      gestorService.getGestores("water", {}, (response) => {
        if (validateShowResponseToast(response, "", false, false)) {
          setGestores(response.data)
          if (gestorSelected && Object.keys(gestorSelected).length === 0) {
            setGestorSelected(response.data[0])
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (gestorSelected.id) {
      const currentVolumeCalc = (gestorSelected.coletasAguas[0]?.consumo_acc || 0) - (gestorSelected.primeiraColetaAgua[0]?.consumo_acc || 0);
      const volumePercentage = ((100 * currentVolumeCalc) / outorga.volume).toFixed(1);

      setVolumePercentage(volumePercentage);
      getColetas();
      setOutorga(gestorSelected.outorgas.find((outorga) => outorga.mes == currentMonth.format('M')))
      setCurrentVolume(parseFloat((currentVolumeCalc).toFixed(3)));
      setIsDataLoaded(true);
    }
  }, [gestorSelected, currentMonth])

  useEffect(() => {
    if (gestorData.groupedByDay) {
      // Added flow unit to the outorga object
      outorga.unidade_vazao = gestorSelected.unidade_vazao;

      setDailyVolume(makeConsumptionByDay([...gestorData.groupedByDay], outorga));
      const calendarDays = makePumpedDays([...gestorData.groupedByDay], outorga);
      setPumpedDays(calendarDays.pumpedDays);
      setOverPumpedDays(calendarDays.overPumpedDays);
      setHoursWorking(makeHoursWorking(gestorData.ungrouped))
      setFlowData(makeFlowWater(gestorData.ungrouped, outorga.vazao));
      setReport([...gestorData.ungrouped].map(item => ({
        ...item,
        volumeOutorgado: outorga.volume,
        vazaoOutorgada: outorga.vazao
      })))
    }
  }, [gestorData])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  const printRelatorio = useCallback(async () => {
    try {
      makeProgressDialog(null, "Gerando relatório...").current.open();

      const objectTableElement = document.getElementById("tableReport");
      if (objectTableElement) {
        objectTableElement.style.display = "none";
      }

      let divWaterReportPng = await htmlToPng(
        document.getElementById("report")
      );

      if (objectTableElement) {
        objectTableElement.style.display = "";
      }

      const doc = template_completo({
        divWaterReportPng,
        headerParams: {
          orientation: "p",
          title: " ",
          data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
          equipamento: gestorSelected?.nome || "",
          local: gestorSelected?.fazenda || "",
          regiao: gestorSelected?.regiao || "",
          data_inicio: moment(currentMonth).format(
            "DD/MM/YYYY - HH:mm:ss"
          ),
          data_final: moment(currentMonth).endOf('month').format("DD/MM/YYYY - HH:mm:ss"),
        },
      });

      const getReportName = () => {
        var nomeArquivo = gestorSelected?.fazenda.toLowerCase();
        nomeArquivo = nomeArquivo.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/\./g, "");
        nomeArquivo += "-relatorio-agua"

        return nomeArquivo;
      }

      doc.save(getReportName());

      closeProgressDialog();
    } catch (error) {
      console.log(error);
      closeProgressDialog();
    }
  }, [gestorSelected]);

  return (
    <div className={styles.reportContainer} id="report">
      <div className={styles.header}>
        <div style={{ width: "12rem" }}>
          <img
            alt="logo"
            style={{ marginLeft: 1, marginTop: 13 }}
            width={"100%"}
            src="/static/images/logo.png"
          ></img>
        </div>
        <div>
          <h2>Relatório</h2>
        </div>
        <div className={styles.menu}>
          {showFilters &&
            <div ref={filterRef}>
              <Filter
                ref={filterRef}
                gestoresList={gestores}
                gestor={gestorSelected}
                period={currentMonth}
                onFilter={handleFilter}
              />
            </div>
          }
          <button
            className={styles.filterButton}
            onClick={toggleFilters}
          > <FilterAlt />Filtro</button>
          <button
            className={styles.generalMenuButton}
            onClick={printRelatorio}
          > <Print /></button>
        </div>
      </div>
      <div className={styles.infoLine}>
        <div>
          <CalendarToday style={{ width: "15px", verticalAlign: "bottom" }} />
          {" "}Periodo do relatório: {moment(currentMonth).format('DD/MM/YYYY')}
          {" "} a {moment(currentMonth).endOf('month').format('DD/MM/YYYY')}
        </div>
        <div>
          <img
            alt="meter-droplet-icon"
            width={"4%"}
            src="/static/images/meter-droplet-light.svg"
          />
          {" "}Medidor: {gestorSelected.fazenda}
        </div>
        <div>
          <img
            alt="location-icon"
            width={"2%"}
            src="/static/images/location-dot-light.svg"
          />
          {" "}Localização: {Number(gestorSelected.lat).toFixed(4)} ; {Number(gestorSelected.long).toFixed(4)}
        </div>
      </div>
      <div className={styles.littleCharts}>
        {isDataLoaded &&
          <div className={styles.pieChartContainer}>
            <div style={{ alignItems: "center" }}>
              <Speed style={{ width: "1.5rem", verticalAlign: "center", paddingRight: "2px" }} />Volume captado
            </div>
            <div>
              <div className={styles.pieChart}>
                <CustomPieChart
                  total={outorga.volume}
                  value={(currentVolume > 0) ? currentVolume : 0}
                  valueColor={"#e57c23"}
                  restColor={"#F0E5FC"}
                  unit={"%"}
                  pieHole={0.7}
                  changeColor={'#F00'}
                />
              </div>
              <div className={styles.pieChartLegend}>
                <div className={styles.legendItem}>
                  <FiberManualRecord style={{ color: `${volumePercentage > 100 ? "#F00" : "#e57c23"}`, marginRight: "3px" }} />
                  <span>Total no mês:</span>
                  <b>{currentVolume} m³</b>
                </div>
                <div className={styles.legendItem}>
                  <FiberManualRecord style={{ color: "#798a81", marginRight: "3px" }} />
                  <span>Outorgado para {currentMonth.format('MMMM')}:</span>
                  <b>{outorga.volume} m³</b>
                </div>
              </div>
            </div>
          </div>
        }
        <div className={styles.calendarContainer}>
          <div className={styles.calendarInfoContainer}>
            <div>
              <DateRange style={{ width: "1.3rem", verticalAlign: "bottom", paddingRight: "2px" }} />Dia/horas captados
            </div>
            <div className={styles.calendarDaysHours}>
              <div>
                <h3>{pumpedDays.length + overPumpedDays.length}</h3> dias captados
              </div>
              <div>
                <h3>{hoursWorking}</h3> horas trabalhadas
              </div>
            </div>
            <div className={styles.calendarCaption}>
              legenda:
              <div className={styles.legendItem}>
                <FiberManualRecord style={{ color: "#D9D9D9", width: "15px" }} /> Não houve captação
              </div>
              <div className={styles.legendItem}>
                <FiberManualRecord style={{ color: "#A1DD70", width: "15px" }} /> Captação dentro do outorgado
              </div>
              <div className={styles.legendItem}>
                <FiberManualRecord style={{ color: "#f2706f", width: "15px" }} /> Captação excedente ao outorgado
              </div>
            </div>
          </div>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
            <DateCalendar
              value={currentMonth.toDate()}
              showDaysOutsideCurrentMonth={false}
              showToolbar={false}
              style={{ width: "fit-content", pointerEvents: "none", }}
              slots={{
                day: serverDay
              }}
              slotProps={{
                calendarHeader: { sx: { display: "none" } },
                day: {
                  pumpedDays,
                  overPumpedDays,
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className={styles.bigCharts}>
        <div className={styles.chart}>
          <div>
            <BarChart style={{ width: "1.25rem", verticalAlign: "bottom", marginRight: "0.3rem" }} />
            Grafico de volume captado
          </div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="100%"
            data={dailyVolume}
            options={volumeDiarioOptions}
          />
          <div className={styles.chartLegend}>
            Legenda:
            <FiberManualRecord style={{ color: "#74c464", marginRight: "3px" }} />
            Volume captado (m³)
            <FiberManualRecord style={{ color: "#d7ffcf", marginRight: "3px" }} />
            Volume acumulado (m³)
            <FiberManualRecord style={{ color: "#798a81", marginRight: "3px" }} />
            Volume captado outorgado (m³/mês)
            <FiberManualRecord style={{ color: "#ff2020", marginRight: "3px" }} />
            Volume captado outorgado (m³/dia)
          </div>
        </div>
        <div className={styles.chart}>
          <div>
            <BarChart style={{ width: "1.25rem", verticalAlign: "bottom", marginRight: "0.3rem" }} />
            Gráfico de vazão (m³/h)
          </div>
          <Chart
            chartType="ComboChart"
            width="100%"
            height="100%"
            data={flowData}
            options={vazaoOptions}
          />
          <div className={styles.chartLegend}>
            Legenda:
            <FiberManualRecord style={{ color: "#4285F4", marginRight: "3px" }} />
            Vazão instantânea (m³/h)
            <FiberManualRecord style={{ color: "#FF2020", marginRight: "3px" }} />
            Vazão outorgada (m³/h)
          </div>
        </div>
        <div className={styles.chart} id="tableReport">
          {
            report &&
            report[0]?.volumeOutorgado &&
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={styles.groupedSelect}>
                <FormControl sx={{
                  m: 1,
                  minWidth: 120,
                  backgroundColor: "white",
                  margin: 0,
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #e8e8e8',
                    borderBottom: 'none', // Remove apenas a borda inferior
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}>
                  <InputLabel>Agrupamento</InputLabel>
                  <Select
                    id="groupedSelect"
                    value={grouping}
                    label="Agrupamento"
                    onChange={(event) => handleChangeGroup(event.target.value)}
                  >
                    {dataGroup.map((group) => (
                      <MenuItem
                        key={group.value}
                        value={group.value}
                      >{`${group.label}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <ObjectTable
                rowId="id"
                tableName="Relatório Detalhado"
                orderBy="data_coleta"
                order="desc"
                headRows={headRowsDetailed || []}
                rows={report}
                rowSize={40}
                rowsOptions={[40]}
                perPage={40}
                actions={[]}
                selectedToolActions={[]}
                toolActions={toolActions}
                ref={dadosTableRef}
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default RelatoriosWater;
