import React from "react"
import Chart from "react-google-charts";

class CustomPieChart extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			options: {},
			data: [],
			fullText: "",
			fullText2: "",
			fullTextColor: "",
			fullText2Color: "",
		}
	}

	componentDidMount() {
		let value = this.props.value;
		let rest = (this.props.total - this.props.value) || 0;
		let valueColor = this.props.valueColor;
		let restColor = this.props.restColor;
		let volumePercentage = ((this.props.value * 100) / this.props.total).toFixed(1);
		let textColor = (this.props.unit == "%" && volumePercentage > 100) ? "#F00" : "#444";
		let text2Color = "#444"
		if (rest < 0) {
			if (this.props.secondLapMax > 0) {
				//segunda volta
				valueColor = "#F00", restColor = "#FF8F00";
				text2Color= "#F00";
				value = value - rest;
				rest = this.props.secondLapMax - rest;
			} else {
				rest = 0;
				value = this.props.total;
				if (this.props.changeColor) {
					valueColor = this.props.changeColor;
					text2Color = this.props.changeColor;
				}
			}
		}

		this.setState({
			options: {
				chartArea: {
					width: "90%",
					height: "90%",
				},
				width: "100%",
				height: "100%",
				backgroundColor: "transparent",
				legend: "none",
				pieHole: this.props.pieHole,
				is3D: false,
				pieSliceText: 'none',
				tooltip: { trigger: 'none' },
				slices: {
					0: { color: valueColor },
					1: { color: restColor }
				}
			},
			fullText: this.props.unit == "%" ?
				`${((this.props.value * 100) / this.props.total).toFixed(1)} %`
				:
				`${this.props.value.toFixed(1)} ${this.props.unit}`,
			fullText2: this.props.extraText,
			fullTextColor: textColor,
			fullText2Color: text2Color,
			data: [
				['teste', 'teste'],
				["value", value],
				["rest", rest]
			],

		})
	}

	render() {
		return (
			<div style={{ height: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div style={{ position: "relative" }}>
					<Chart
						chartType="PieChart"
						data={this.state.data}
						options={this.state.options}
					/>
					<div style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						fontSize: "14px",
						textWrap: "nowrap"
					}}>
						<div style={{ fontSize: "14px", color: this.state.fullTextColor }}>{this.state.fullText}</div>
						<div style={{ fontSize: "10px", color: this.state.fullText2Color }}>{this.state.fullText2}</div>
					</div>
				</div>
			</div>
		)
	}
}

export default CustomPieChart